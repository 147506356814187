@import 'tailwindcss/utilities';

@import '@jouzen/ecom-components/dist/index.css';

html {
  /* crucial for a11y */
  font-size: revert !important;
}

html,
body {
  scroll-behavior: smooth;
  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}
